<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="col-sm-12 col-md-6">
      <div v-if="authType == 'firebase'">
        <CCard>
          <CCardHeader>メドシェア 薬局情報 ログイン</CCardHeader>
          <CCardBody>
            <CForm>
              <CInput v-model="user.email" label="メールアドレス" horizontal />
              <CInput v-model="user.password" type="password" label="パスワード" horizontal />
            </CForm>
            <CCol col="12" v-if="errors.length > 0">
              <CAlert color="danger">
                <div>エラーが発生しました。</div>
                <ul class="my-1">
                  <li v-for="msg in errors" :key="msg">{{ msg }}</li>
                </ul>
              </CAlert>
            </CCol>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="loginFirebase">ログイン</CButton>
            <div class="float-right">
              <CButton color="outlined" @click="openResetPasswordModal">パスワードを忘れた方はこちら</CButton>
            </div>
            <ResetPasswordModal :open-modal.sync="isOpenResetPasswordModal" />
          </CCardFooter>
        </CCard>
      </div>
      <div v-else>
        <!-- Check that the SDK client is not currently loading before accessing is methods -->
        <div v-if="!$auth.loading">
          <!-- show login when not authenticated -->
          <button v-if="!$auth.isAuthenticated" @click="loginAuth0">Log in</button>
          <!-- show logout when authenticated -->
          <button v-if="$auth.isAuthenticated" @click="logoutAuth0">Log out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, errorMessage } from "@/auth/firebase";
import ResetPasswordModal from "@/components/ResetPasswordModal";

export default {
  name: "Login",
  components: { ResetPasswordModal },
  data() {
    return {
      user: { email: null, password: null },
      authType: process.env.VUE_APP_AUTH_TYPE,
      errors: [],
      isOpenResetPasswordModal: false,
    };
  },
  methods: {
    loginAuth0() {
      this.$auth.loginWithRedirect();
    },
    logoutAuth0() {
      this.$auth.logout({
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
      });
    },
    loginFirebase() {
      this.errors = [];
      login(this.user.email, this.user.password)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.errors = [errorMessage(error, "default") || error.message];
          console.error("Login error:", error);
        });
    },
    openResetPasswordModal() {
      this.isOpenResetPasswordModal = true;
    },
  },
};
</script>

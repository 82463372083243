import Axios from "axios";
import { getInstance } from "src/auth";
import { getCurrentUserId } from "@/auth/firebase.js";

const http = Axios.create({});

http.interceptors.request.use(async (request) => {
  const url = request.url;

  // V2 APIの場合
  if (url.startsWith("/api/v2")) {
    // Authorization 付与
    if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
      const uid = await getCurrentUserId();
      request.headers.Authorization = `Bearer ${uid}`;
    } else {
      const jwtToken = await getInstance().getJwtToken();
      request.headers.Authorization = `Bearer ${jwtToken}`;
    }

    // サービスごとにhostを書き換える
    const version = url.split("/")[2];
    const module = url.split("/")[3];
    const path = url.replace(`/api/${version}/${module}/`, "");

    const host = ((_module) => {
      switch (_module) {
        case "core":
          return process.env.VUE_APP_V2_API_CORE_HOST;
        case "medorder":
          return process.env.VUE_APP_V2_API_MEDORDER_HOST;
        default:
          throw `url module(${_module}) not defined error`;
      }
    })(module);

    request.url = `${host}/api/${version}/${path}`;
  }

  return request;
});

// http.interceptors.response.use(
//   function(result) {
//     return Promise.resolve(result);
//   },
//   function(error) {
//     if (error.response.status === 401) {
//       Vue.toasted.clear();
//       Vue.toasted.error(error.response.data.message);
//     } else if (error.response.status === 500) {
//       Vue.toasted.clear();
//       Vue.toasted.error(error.response.data.message);
//     }
//     return Promise.reject(error);
//   }
// );

export default http;

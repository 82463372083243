
























import { getInstance } from "@/auth";
import { getCurrentUserId } from "@/auth/firebase";
import { selectingPharmacy } from "@/store/selectingPharmacy";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { createConsumer } from "actioncable-jwt";

export default defineComponent({
  name: "TheSidebar",
  setup(props, context) {
    let unreadRoomsCable: ActionCableJwt.Cable | undefined = undefined;
    let unreadRoomsChannel: ActionCableJwt.Channel | undefined = undefined;

    const minimize = ref<boolean>(false);
    const show = ref<string | boolean>("responsive");
    const unreadRoomCount = ref<number>(0);
    const inquiryBadge = computed(() => {
      return unreadRoomCount.value === 0 ? {} : { color: "danger", text: unreadRoomCount.value };
    });

    const pharmacyId = typeof selectingPharmacy.state === "object" ? selectingPharmacy.state?.id : 0;

    onMounted(() => {
      context.root.$on("toggle-sidebar", () => {
        const sidebarOpened = show.value === true || show.value === "responsive";
        show.value = sidebarOpened ? false : "responsive";
      });
      context.root.$on("toggle-sidebar-mobile", () => {
        const sidebarClosed = show.value === "responsive" || show.value === false;
        show.value = sidebarClosed ? true : "responsive";
      });
    });

    function reverseMinimizer() {
      minimize.value = !minimize.value;
    }

    async function subscribeRoomReads() {
      let jwtToken;
      if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
        const u = await getCurrentUserId();
        jwtToken = u;
      } else {
        jwtToken = await getInstance().getJwtToken();
      }
      unreadRoomsChannel?.unsubscribe();
      unreadRoomsCable?.disconnect();

      unreadRoomsCable = createConsumer(`${process.env.VUE_APP_V2_API_CORE_HOST_WS}/cable`, jwtToken);
      unreadRoomsChannel = unreadRoomsCable?.subscriptions.create(
        { channel: "PharmacyInquiryUnreadRoomsChannel", pharmacy_id: pharmacyId },
        {
          received: (unreadRooms: object[]) => {
            unreadRoomCount.value = unreadRooms.length;
          },
        }
      );
    }
    subscribeRoomReads();

    return {
      minimize,
      show,
      unreadInquiryCount: unreadRoomCount,
      inquiryBadge,
      reverseMinimizer,
    };
  },
});

import { getInstance } from "./index";
import { default as store } from "@/store";
import { getCurrentUser } from "@/auth/firebase";

export const auth0Guard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (!authService.isAuthenticated) {
      // 認証されていない場合は、ログインページにリダイレクト
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }

    // 薬局ロールもしくは管理者ロールのいずれも持たない場合は、Forbiddenページへ。
    if (!authService.hasPharmacyRole && !authService.hasAdminRole) {
      return next("/forbidden");
    }

    // 選択薬局がない場合は、選択ページへ。
    if (!store.getters["selectingPharmacy/id"]) {
      // リダイレクトループしないように、to.pathをチェック
      if (to.path !== "/select-pharmacy") {
        return next("/select-pharmacy");
      }
    }
    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

async function firebaseGuard(to, from, next) {
  const user = await getCurrentUser();
  if (user) {
    // 選択薬局がない場合は、選択ページへ。
    if (!store.getters["selectingPharmacy/id"]) {
      // リダイレクトループしないように、to.pathをチェック
      if (to.path !== "/select-pharmacy") {
        return next("/select-pharmacy");
      }
    }
    if (to.path == "/login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.path == "/login") {
      next();
    } else {
      next("/login");
    }
  }
}

export const authGuard = (to, from, next) => {
  if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
    firebaseGuard(to, from, next);
  } else {
    auth0Guard(to, from, next);
  }
};

import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";

const FIREBASE_API = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};

const firebaseApp = (() => {
  if (!getApps().length) {
    return initializeApp(FIREBASE_API);
  }
  return getApp();
})();

const auth = getAuth(firebaseApp);

export { auth };

// LOGIN
export const login = async (email, password) => {
  await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
};

export const userSendPasswordResetEmail = (email) => sendPasswordResetEmail(getAuth(firebaseApp), email);

// LOGOUT
export const logout = async () => signOut(getAuth(firebaseApp));

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

export const getCurrentUserId = async () => (await getCurrentUser())?.uid || "";

export let hasFirebaseAdminRole = false;
export const isAdmin = (u) => {
  if (!u) return false;
  const attributes = u?.reloadUserInfo?.customAttributes;
  let claims;
  try {
    claims = JSON.parse(attributes);
  } catch (e) {
    console.log(e);
  }
  return !!claims?.admin;
};
(async () => (hasFirebaseAdminRole = isAdmin(await getCurrentUser())))();

export const errorMessage = (e, method) => {
  switch (e.code) {
    case "auth/cancelled-popup-request":
    case "auth/popup-closed-by-user":
      return null;
    case "auth/email-already-in-use":
      if (method.indexOf("signup") !== -1) {
        return "このメールアドレスは使用されています";
      }
      return "メールアドレスまたはパスワードが違います";
    case "auth/invalid-email":
      return "メールアドレスの形式が正しくありません";
    case "auth/user-disabled":
      return "サービスの利用が停止されています";
    case "auth/user-not-found":
      return "メールアドレスまたはパスワードが違います";
    case "auth/user-mismatch":
      if (method === "signin/popup") {
        return "認証されているユーザーと異なるアカウントが選択されました";
      }
      return "メールアドレスまたはパスワードが違います";

    case "auth/weak-password":
      return "パスワードは6文字以上にしてください";
    case "auth/wrong-password":
      return "メールアドレスまたはパスワードが違います";
    case "auth/popup-blocked":
      return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
    case "auth/operation-not-supported-in-this-environment":
    case "auth/auth-domain-config-required":
    case "auth/operation-not-allowed":
    case "auth/unauthorized-domain":
      return "現在この認証方法はご利用頂けません";
    case "auth/requires-recent-login":
      return "認証の有効期限が切れています";
    default:
      if (method.indexOf("signin") !== -1) {
        return "認証に失敗しました。しばらく時間をおいて再度お試しください";
      }
      return "エラーが発生しました。しばらく時間をおいてお試しください";
  }
};

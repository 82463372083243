import { authGuard } from "@/auth/authGuard";
import EmptyContainer from "@/containers/EmptyContainer";
import TheContainer from "@/containers/TheContainer";
import ChangePassword from "@/views/ChangePassword";
import Jwt from "@/views/develop/Jwt";
import UserRestore from "@/views/develop/UserRestore";
import Inquiries from "@/views/inquiries/Inquiries";
import Pharmacy from "@/views/Pharmacy";
import PharmacySetting from "@/views/PharmacySetting";
import Profile from "@/views/Profile";
import Login from "@/views/shared/Login";
import Page403 from "@/views/shared/Page403";
import Page404 from "@/views/shared/Page404";
import ResetPharmacy from "@/views/shared/ResetPharmacy";
import SelectPharmacy from "@/views/shared/SelectPharmacy";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/login", component: Login },
  { path: "/forbidden", component: Page403 },
  {
    path: "/",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [
      { path: "/", redirect: "profile" },
      { path: "profile", name: "ユーザ情報", component: Profile },
      { path: "change-password", name: "パスワード変更", component: ChangePassword },
      { path: "pharmacy", name: "薬局情報", component: Pharmacy },
      // { path: "pharmacy-setting", name: "薬局設定", component: PharmacySetting },
      // {
      //   path: "inquiries",
      //   redirect: "inquiries",
      //   name: "お問い合わせ",
      //   component: EmptyContainer,
      //   children: [{ path: "/", component: Inquiries }],
      // },
    ],
  },
  {
    path: "/develop",
    name: "開発",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [
      { path: "jwt", name: "JWT", component: Jwt },
      { path: "user-restore", name: "ユーザ復元", component: UserRestore },
    ],
  },
  { path: "/select-pharmacy", component: SelectPharmacy, beforeEnter: authGuard },
  { path: "/reset-pharmacy", component: ResetPharmacy },
  { path: "*", component: Page404 },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

<template>
  <CHeader fixed with-subheader light class="electron-draggable-area">
    <CToggler v-c-emit-root-event:toggle-sidebar-mobile in-header class="ml-3 d-lg-none" />
    <CToggler v-c-emit-root-event:toggle-sidebar in-header class="ml-3 d-md-down-none" />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">薬局情報</CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CButton v-show="isLoadedPharmacyName" color="primary" variant="outline" @click="openSelectPharmacyModal">
          {{ pharmacyName }}
        </CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :href="medorder_url">メドオーダー</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :href="medshare_url">メドシェア</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccount />
    </CHeaderNav>

    <SubHeaderInquires v-if="$route.fullPath.startsWith('/inquiries')"></SubHeaderInquires>
    <CSubheader v-else class="justify-content-between px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>

    <CModal title="表示する薬局を切り替える" size="lg" :show.sync="isOpenSelectPharmacyModal">
      <SelectPharmacyTable button-name="切り替え" @selectPharmacy="selectPharmacy" />
      <template #footer>
        <CButton color="secondary" @click="closeSelectPharmacyModal">閉じる</CButton>
      </template>
    </CModal>
  </CHeader>
</template>

<script>
import SubHeaderInquires from "@/containers/subHeaders/SubHeaderInquires";
import TheHeaderDropdownAccount from "./TheHeaderDropdownAccount";
import SelectPharmacyTable from "src/components/SelectPharmacyTable";

export default {
  name: "TheHeader",
  components: {
    SubHeaderInquires,
    SelectPharmacyTable: SelectPharmacyTable,
    TheHeaderDropdownAccount: TheHeaderDropdownAccount,
  },
  data() {
    return {
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
      medshare_url: process.env.VUE_APP_V2_APP_MEDSHARE_HOST,
      isOpenSelectPharmacyModal: false,
    };
  },
  computed: {
    pharmacyId() {
      return this.$store.getters["selectingPharmacy/id"];
    },
    isLoadedPharmacyName() {
      return this.$store.state.core.pharmacies.isFetched;
    },
    pharmacyName() {
      return this.$store.state.core.pharmacies.selecting?.name || "-";
    },
  },
  async created() {
    await this.$store.dispatch("core/pharmacies/fetch");
  },
  methods: {
    openSelectPharmacyModal() {
      this.isOpenSelectPharmacyModal = true;
    },
    closeSelectPharmacyModal() {
      this.isOpenSelectPharmacyModal = false;
    },
    selectPharmacy(pharmacy) {
      this.$store.dispatch("selectingPharmacy/set", pharmacy.id);

      // 強制リロードする
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>

<style>
.electron-draggable-area {
  -webkit-app-region: drag;
}
</style>

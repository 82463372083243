<template>
  <CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink v-if="authType == 'firebase'">
        <div class="c-avatar">
          <img :src="src" class="c-avatar-img" />
        </div>
        <span class="ml-1">{{ username }}</span>
      </CHeaderNavLink>
      <CHeaderNavLink v-else>
        <div class="c-avatar">
          <img :src="$auth.user.picture" class="c-avatar-img" />
        </div>
        <span class="ml-1">{{ $auth.user.name }}</span>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader class="text-center" color="light">
      <strong>バージョン: {{ require("../../package.json").version }}</strong>
    </CDropdownHeader>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>設定</strong>
    </CDropdownHeader>
    <CDropdownItem :to="{ name: 'ユーザ情報' }">ユーザ情報</CDropdownItem>
    <CDropdownItem v-show="isDevelop" :to="{ name: 'ユーザ復元' }">ユーザ復元(localのみ表示)</CDropdownItem>

    <CDropdownDivider />

    <CDropdownItem @click="handleLogout">ログアウト</CDropdownItem>
  </CDropdown>
</template>

<script>
import Vue from "vue";
import { getCurrentUser, logout } from "@/auth/firebase";

export default {
  name: "TheHeaderDropdownAccount",
  data() {
    return {
      authType: process.env.VUE_APP_AUTH_TYPE,
      username: "",
      src: "",
    };
  },
  computed: {
    isDevelop() {
      return process.env.VUE_APP_ENV === "develop";
    },
  },
  async mounted() {
    const user = await getCurrentUser();
    if (user) {
      this.username = user.displayName;
      this.src = user.photoURL;
    }
    await Vue.nextTick();
  },
  methods: {
    handleLogout() {
      if (this.authType == "firebase") {
        logout()
          .then(() => {
            this.$router.push("/login");
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
      } else {
        this.$auth.logout({
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          returnTo: window.location.origin,
        });
      }
    },
  },
};
</script>
